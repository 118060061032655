<template>

	<div :style="{background: theme.background}">
		<!--<Carousel v-if="this.$store.getters.isOnline"/>-->
	
	<v-container>
		<v-row 
			align="center"
			justify="center">
			<v-col cols="12" sm="5">
				<v-img src="/img/fridge.svg">
				</v-img>
			</v-col>
			<v-col cols="12" sm="7">
				<div class="text-h4 text-lg-h2 text-sm-h3"  :style="{'font-weight':700,color: theme.title_color, 'letter-spacing': '0.4vw !important'}">
					Bruk matvarer du allerede har
				</div>
				<div class="mt-2" style="font-size:22px; font-family: Open Sans,Arial,sans-serif; color:#666">Finn oppskrifter fra matvarer du har i kjøleskapet eller ernæringsbehov.</div>
				<v-row class="mt-4 	d-none d-sm-flex">
					<v-col cols="6">
						<ICountUp
							style="font-size:30px; font-weight:500;"
							:delay="delay"
							:endVal="endVal_ing"
							:options="options_ing"
						/> 
						<br>Med komplett næringsinnhold
					</v-col>
					<v-col cols="6">
						<ICountUp
							style="font-size:30px; font-weight:500;"
							:delay="delay"
							:endVal="endVal_opp"
							:options="options_opp"
						/> 
						<br>Vi kommer snart med flere!
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row class="text-center">
			<v-col cols="12">
				<!--<v-img
					src="http://brukmat.no/brukmat_logo.svg"
					class="my-3"
					contain
					height="200"
				/>-->

			</v-col>

			<v-col class="mb-4" cols="12">
				
				<!--<h1 class="display-2 font-weight-bold mb-3">
					Velkommen til BrukMat
				</h1>

				<h2 class="subheading font-weight-regular mt-2">
					Prøv å søk på noen ingredienser!
				</h2>-->
				<v-autocomplete
					label="Ingredienser"
					rounded
					prepend-inner-icon="mdi-magnify"
					v-model="values"
					:items="items"
					:loading="loading"
					:search-input.sync="search"
					auto-select-first
					multiple
					filled
					hide-no-data
					placeholder="Skriv for å begynne søket"
				></v-autocomplete>
				<v-row>
					<v-col cols="12" sm="6">
						<v-btn
							block
							rounded
							large
							:disabled="$store.getters.searchText.length == 0"
							:style="{color:theme.primary_btn_text}"
							:color="theme.primary_btn"
							elevation="2"
							@click="searchRecipes"
						>
						Søk På Oppskrifter
						</v-btn>
					</v-col>
					<v-col cols="12" sm="6">
						<v-btn
							block
							rounded
							large
							elevation="2"
							:color="theme.primary_btn"
							:style="{color:theme.primary_btn_text}"
							:disabled="$store.getters.sidebarToggle === true"
							@click="$store.commit('toggleSidebar', true)"
						>
							Åpne Matkurv
							<v-icon right>mdi-food-apple</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				
				<h2 class="subheading font-weight-regular my-4">Er du interessert i næringsinnhold?</h2>
				<!--<v-alert
					rounded
					type="info"
				>Næringssøk er funksjonelt men utvikles fortsatt.</v-alert>-->
				<NutrientSearch/>
				
			</v-col>

		</v-row>
		<v-snackbar v-model="snackbar">
			{{ snackbar_text }}

			<template v-slot:action="{ attrs }">
				<v-btn
					color="pink"
					text
					v-bind="attrs"
					@click="snackbar = false"
				>
					Close
				</v-btn>
			</template>
		</v-snackbar>
	</v-container>
	</div>
	
</template>
<script>
	//import Carousel from '../components/RecipeCarousel';
	import NutrientSearch from '../components/NutrientSearch';
	import ICountUp from 'vue-countup-v2';
	import axios from 'axios';

	var data = {
		value:'',
		loading:false,
		items:[],
		snackbar: false,
		snackbar_text:'',
		search:'',
		delay: 1000,
		endVal_ing: 1898,
		endVal_opp: 0,
		options_ing: {
			useEasing: true,
			useGrouping: true,
			suffix: ' Matvarer'
		},
		options_opp: {
			useEasing: true,
			useGrouping: true,
			suffix: ' Oppskrifter'
		},
		debound:debounce(function(val){
			this.loading = true;
			/*if(connectedToLocalDb === true){
				if(localStorage.getItem("_query="+val) !== null){
					this.loading = false;
					this.items = JSON.parse(localStorage.getItem("_query="+val));
					return;
				}
			}*/
			
			axios.get('https://api.brukmat.no/ingredients/search/?ingredient='+val).then((res)=>{
				this.loading = false;

				let ingredients = res.data.map((item)=>{
					let keywords = item.keywords.join(", ");
					if(!keywords) return {product:item.product, raw:item};
					return {product: item.product + " - "+keywords, raw:item};
				});
				let products = ingredients.map((item)=>{
					return item.product;
				});
				
				this.items = products;
				
				this.$db.then((db_obj)=>{
					var objectStore = db_obj.transaction(["ingredients"], "readwrite").objectStore("ingredients");
					for(let ingredient of ingredients){
						let data = {
							ingredientName: ingredient.product,
							...ingredient.raw
						}
						console.log(data);
						objectStore.put(data);
					}
				});
				this.$db.then((db_obj)=>{
					var objectStore = db_obj.transaction(["queries"], "readwrite").objectStore("queries");
					let data = {
						searchValue: val,
						properties: products
					}
					objectStore.put(data);
					/*for(let product of products){
						let data = {
							searchValue:product,
							property:"Example property"
						}
						objectStore.put(data);
						console.log("Data stored");
					}*/
				})

				
			}).catch((err)=>{
				this.snackbar = true;
				this.snackbar_text = "En nettverksfeil oppstod";
				console.log(err);
			});
			console.log(val);
		}, 250),
	}


	export default {
		components:{
			//Carousel,
			NutrientSearch,
			ICountUp
		},
		metaInfo: {
			// override the parent template and just use the above title only
			title: 'Hjem',
			// all titles will be injected into this template
			titleTemplate: '%s | Brukmat',
			meta: [
				{ name: 'description', content: 'BrukMat er en næringsoversikt og matplanlegger som prioriterer ingredienser først. BrukMat er for de med allergi, spesielle kostholdskrav og for de som ønsker å redusere matsvinn.' }
			]
		},
		data (){
			return data;
		},
		computed: {
			theme(){
				return this.$vuetify.theme.themes[(this.$vuetify.theme.dark) ? 'dark' : 'light'];
			},
			values:{
				set(items){
					// When user selects an item
					console.log("Picked",items);
					this.search = '';
					//this.$store.commit('toggleSidebar', true);

					if(this.$db){
						this.$db.then(db_obj=>{
							let objectStore = db_obj.transaction(["ingredients"], "readwrite").objectStore("ingredients");
							let DBResults = [];
							for(let item of items){
								let promise = new Promise((resolve)=>{
									let request = objectStore.get(item);
									console.log(request);
									request.onsuccess = (result)=>{
										console.log("Completed");
										resolve(result.target.result);
									};
								});
								DBResults.push(promise);
							}
							console.log(DBResults);
							Promise.allSettled(DBResults).then((result)=>{
								let values = result.map(item=>item.value);
								this.snackbar = true;
								this.snackbar_text = "Vi har lagt til ingrediensen i listen din";
								if(values.length === 0) values = false;
								this.$store.commit("selectedIngredientsTitles", items)
								this.$store.commit('changeText', values);
								for(let item of result){
									console.log(item.value);
								}
							});
						})
					}
				},
				get() {
					console.log("Raw search",this.$store.getters.selectedIngredientsTitles);
					return this.$store.getters.selectedIngredientsTitles;
				}
			}
		},
		methods:{
			searchRecipes(){
				///recipes/?ingredient=cheese&ingredient=onion
				console.log(this.$store.getters.searchText)
				//this.$router.push({ path: '/show-recipes' });
				let products = this.$store.getters.searchText.map(item => {
					return {product:item.product, keywords:item.keywords};
				});
				let headers = {
					'Content-Type': 'application/json'
				};
				console.log(products);
				axios.post('https://api.brukmat.no/recipe-from-ingredients', products, {headers:headers}).then((returnedData)=>{
						console.log("Returned data:",returnedData.data.data)
						var recipes = {};
						for(let items of returnedData.data.data){
						/*let recipesAll = items.reduce((acc, item)=>{
						if(!acc) acc={};
						console.log(acc);
						if(item.labels && item.labels.includes("Recipe")){
							if(!acc.title) item.properties.title;
						}
						});*/
						let title = null;
						let ingredientToPush = {};
						// First get the title
						for(let item of items){
							if(item.labels && item.labels.includes("Recipe")){
								if(!recipes[item.properties.title]) {
									console.log("Setting title etc.");
									recipes[item.properties.title] = {
									ingredients:[],
									img_url:item.properties.img_url,
									mood:item.properties.mood,
									difficulty:item.properties.difficulty
								};
							}
							title = item.properties.title;
							break;
							}
						}
						console.log("Title",title);
						if(!recipes[title]) throw Error("Malformed data returned...");
						// Populate the array
						for(let item of items){
							console.log("Items:",item);
							if(item.labels && item.labels.includes("Ingredient_Container")){
								if(title === null) throw Error("Missing title...");
								ingredientToPush = { ...ingredientToPush, ...item.properties};
							}
							if(item.type && item.type === "USED_IN"){
								if(title === null) throw Error("Missing title...");
								ingredientToPush = { ...ingredientToPush, ...item.properties};
							}
						}
						recipes[title].ingredients.push(ingredientToPush);
					}
					console.log(recipes);
					let resultArray = [];
					for (let prop in recipes){
						let values = recipes[prop];
						let finalValues = {...values, ...{title:prop}}
						resultArray.push(finalValues);
					}
					console.log(resultArray);
					this.$store.commit('setRecipes', resultArray);
					this.$router.push({ path: '/show-recipes' });
				}).catch(()=>{
					//this.snackbar = true;
					//this.snackbar_text = "En nettverksfeil oppstod";
				});
			}
		},
		beforeCreate:function(){
			axios.get('https://api.brukmat.no/recipes/count').then((val)=>{
				console.log(val);
				this.endVal_opp = val.data.count;
			});
		},
		watch:{
			search(val){
				console.log("Registered press...");
				// Check if value already exists in DB...
				//this.$emit('changeSearch', val);
				if(!val || val.length < 2) return false;
				val = val.toLowerCase();
				if(this.$db){
					try{
						this.$db.then(db_obj=>{
							var objectStore = db_obj.transaction(["queries"], "readwrite").objectStore("queries");
							var request = objectStore.get(val);
							request.onsuccess = (result)=>{
								let items = result.target.result;
								if(!items) {
									this.debound(val);
								} else {
									console.log("Found previous search value")
									this.items = items.properties;
								}
							}
						});
					} catch(err){
						console.log("Got error", err);
						this.debound(val);

					}
					
				} else {
					this.debound(val);
				}
				
				
			}
		}
	}
	function debounce(func, wait, immediate) {
		var timeout;
		return function() {
			var context = this, args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	}
</script>